import './App.css';
import { useState, useEffect } from 'react';

const Timer = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const deadline = "December 31, 2024";

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{fontFamily: '"Pixelify Sans", sans-serif;'}} className="timer">
      {days}:{hours}:{minutes}:{seconds}
    </div>
  )
}

function App() {
  useEffect(() => {
    document.getElementById('background-video').play();

    setInterval(() => {
      document.getElementsByClassName('glitch')[0].classList.add('effect')
    }, 500)

    setInterval(() => {
      document.getElementsByClassName('glitch')[0].classList.remove('effect')
    }, 800)
  }, [])

  return (
    <div style={{margin: '0 auto'}} className="App">
      <video id="background-video" autoPlay loop muted >
        <source src={window.innerWidth < 600 ? "/fast.mp4" : "/slow.mp4"} type="video/mp4" />
      </video>
      
      <header className="App-header" style={{display: 'flex', flexDirection: 'column'}}>
        <h1 className="glitch" style={{marginTop: '0em', marginBottom: '0'}}>
          awarehouse
        </h1>
        <div style={{display: "flex"}}>
          <a target="_blank" href="https://instagram.com/awarehouse.link">
          <img style={{pointer: 'cursor', marginRight: '12px', position: 'relative', bottom: '10px'}} height="60px" width="60px" src="/pixel-link.png" alt="link image" /></a> <Timer />
        </div>
      </header>
    </div>
  );
}

export default App;
